<template>
  <v-row class="fill-height">
    <v-col>
      <v-row>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <v-icon
                @click="pushRouteToView('employees')"
                class="mr-4"
                style="cursor: pointer"
              >
                mdi-arrow-left
              </v-icon>
              <v-icon class="mr-2">mdi-account-hard-hat</v-icon>
              Editar funcionário
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col md="12">
          <v-form
            v-model="submitNewEmployee"
            ref="submitNewEmployee"
            @submit.prevent="submitEmployee()"
          >
            <v-row justify="center">
              <v-col lg="3" md="4" sm="12">
                <v-text-field
                  label="nome"
                  name="name"
                  outlined
                  v-model="name"
                  :rules="textRules"
                />
              </v-col>

              <v-col lg="3" md="4" sm="12">
                <v-text-field
                  outlined
                  label="CPF"
                  name="cpf"
                  v-model="cpf"
                  v-mask="['###.###.###-##']"
                  :rules="textRules"
                />
              </v-col>

              <v-col lg="3" md="4" sm="12">
                <v-select
                  v-model="categories"
                  :items="items"
                  item-text="desc"
                  item-value="value"
                  label="Categorias"
                  :rules="textRules"
                  outlined
                ></v-select>
              </v-col>

              <v-col sm="10" class="text-right">
                <v-btn
                  type="submit"
                  style="color: #fff; background-color: rgb(61, 17, 89)"
                >
                  <v-icon left>
                    mdi-check
                  </v-icon>
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  name: "Employees",

  directives: { mask },

  data: () => ({
    submitNewEmployee: false,
    cpfRule: [
      v => !!v || "CPF é obrigatório",
      strCPF => {
        if (!strCPF) return false;
        strCPF = strCPF.replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF == "00000000000") return "CPF Inválido";
        let i = 0;
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
        return true;
      }
    ],
    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    // v-model
    name: "",
    cpf: "",
    categories: "",

    items: [
      { value: 1, desc: "categoria 1" },
      { value: 2, desc: "categoria 2" },
      { value: 3, desc: "categoria 3" }
    ]
  }),

  methods: {
    pushRouteToView(route) {
      this.$router.push({ name: route });
    },
    submitEmployee() {
      console.log("submit new employee");
    }
  }
};
</script>
